<template>
  <div id="messages-box" style="height: calc(100vh - 125px)">
    <div class="top-bar">
      <div class="search-bar">
        <el-input style="width: 100%" :placeholder="$localization('web-messages-search')" prefix-icon="el-icon-search" v-model="filter.SearchTerm"></el-input>
      </div>
      <div class="date-bar">
        <el-select v-model="filter.SortingType" :placeholder="$localization('web-messages-order')">
          <el-option v-for="item in sortingOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
    </div>
    <div class="bottom-bar" style="overflow: auto; max-height: calc(100vh - 125px)">
      <ul style="margin: 0px">
        <li style="padding: 10px 0px !important; height: unset" v-for="(item, index) in messages.Items" :key="item.ID + index" @click="setActiveMessage(item)" :style="activeMessage != null && activeMessage.ID == item.ID ? 'background-color:#FFF' : ''">
          <div class="li-left">
            <div class="circle">{{ item.Sender.Title.substr(0, 1).toUpperCase() }}</div>
          </div>
          <div class="li-right">
            <div class="title">
              <span v-if="item.Receiver.Id != currentUser.ID">
                {{ item.Receiver.Title }}
              </span>
              <span v-else> {{ item.Sender.Title }} <i v-if="item.ReadedAt == null" class="icon-red-dot"></i> </span>
              <div>
                <i style="background: red" class="icon-flag" v-if="item.HasFlag"></i>
                <i style="background: #8697a8" class="icon-attachment" v-if="item.Attachments != null && item.Attachments.length > 0"></i> {{ $moment(item.CreatedDateTime).fromNow() }}
              </div>
            </div>
            <div class="message-content" style="margin: 0px; padding: 0px; font-weight: bold; height: auto">{{ item.Title }}</div>
            <div class="message-content" v-html="item.MessageText" style="height: auto; max-height: 40px; overflow: hidden; background: none"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sortingOptions: [
        {
          value: 1,
          label: this.$localization('web-messages-sort-by-date-desc'),
        },
        {
          value: 2,
          label: this.$localization('web-messages-sort-by-date-asc'),
        },
        {
          value: 3,
          label: this.$localization('web-messages-sort-by-name-asc'),
        },
      ],
      SearchTimeout: null,
    };
  },
  methods: {
    setActiveMessage(item) {
      if (item.ReadedAt == null && item.Sender.Id != this.currentUser.ID) {
        this.$store.dispatch("toggleIsReadMB", {MessageId: item.ID});
        item.ReadedAt = 1;
      }
      this.$store.commit("setActiveMessageMB", item);
    },
  },
  watch: {
    "filter.SortingType": function () {
      this.$store.commit("setActiveMessageMB", null);
      this.$store.dispatch("getMessagesMB", this.filter);
    },
    "filter.SearchTerm": function () {
      window.clearTimeout(this.SearchTimeout);
      this.SearchTimeout = setTimeout(() => {
        this.$store.commit("setActiveMessageMB", null);
        this.$store.dispatch("getMessagesMB", this.filter);
      }, 800);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    filter() {
      return this.$store.getters.getFilterMB;
    },
    messages() {
      return this.$store.getters.getMessagesMB;
    },
    activeMessage() {
      return this.$store.getters.getActiveMessageMB;
    },
  },
};
</script>

<style lang="less">
.date-bar input {
  background: none !important;
  border: none !important;
  font-size: 14px !important;
  color: #8697a8 !important;
}

#messages-box {
  width: 100%;
  height: 100vh;
  border-left: 1px solid #eaedf0;
  border-right: 1px solid #eaedf0;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8697a8;
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-top: none;
    border-bottom: 1px solid #eaedf0;
    padding: 10px;
    .search-bar {
      .el-input {
        width: 50%;
        input {
          border: none;
          background-color: unset;
        }
        .el-input__prefix {
          i {
            color: #8697a8;
          }
        }
        input::placeholder {
          color: #8697a8;
        }
      }
    }
    .date-bar {
      display: flex;
      color: #8697a8;

      font-weight: 400;
      font-size: 15px;
      .date {
        color: #44566c;
        i {
          float: right;
        }
      }
    }
  }

  .bottom-bar {
    ul {
      padding: 0;
      list-style: none;
      li {
        display: flex;
        height: 103px;
        border-bottom: 1px solid #eaedf0;
        align-items: center;
        &.active {
          position: relative;
          background: #f8fafb;
        }

        .li-left {
          width: 20%;
          display: flex;
          justify-content: center;
          .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background: #ff8a48;
            color: #fff;
            border-radius: 25px;
          }
        }
        .li-right {
          width: 80%;

          display: flex;
          flex-direction: column;
          .title {
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 15px;
            color: #44566c;
            padding-bottom: 3px;
            div {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 13px;
              line-height: 15px;
              color: #8697a8;
              padding-right: 20px;

              i {
                margin-right: 15px;
              }
            }
          }
          & > .message-content {
            width: 100%;
            height: 39px;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #8697a8;
            padding-right: 5px;
            margin-bottom: 10px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
</style>
