<template>
  <div id="folders-box">
    <div class="folders" style="height: calc(100vh - 65px)">
      <div class="folders-top" v-if="currentUser.Role == 10 || currentUser.Role == 12">
        <el-button @click="dialogVisible = true" class="btn-new-message">
          <div class="content1">
            <i class="icon-task"></i>
            <div>{{ $localization('web-folders-messages-new-message') }}</div>
          </div>
        </el-button>
      </div>
      <div class="folders-bottom">
        <div class="folders-bottom-folders">
          <ul style="cursor: pointer">
            <li :class="{ active: filter.MessageBoxType == 1 }" @click="filter.MessageBoxType = 1">
              <div><i class="icon-inbox"></i> {{ $localization('web-folders-messages-inbox') }}</div>
            </li>
            <li :class="{ active: filter.MessageBoxType == 2 }" @click="filter.MessageBoxType = 2">
              <div><i style="background-color: #b3c0ce" class="icon-send"></i>{{ $localization('web-folders-messages-sent') }}</div>
            </li>
            <li :class="{ active: filter.MessageBoxType == 3 }" @click="filter.MessageBoxType = 3">
              <div><i class="icon-delete"></i>{{ $localization('web-folders-messages-deleted') }}</div>
            </li>
          </ul>
        </div>
        <div class="folders-bottom-tags" v-if="filter.MessageBoxType == 1">
          <div class="tag">{{ $localization('web-folders-messages-tags-upper') }}</div>
          <ul style="cursor: pointer">
            <li :class="{ active: filter.Tag == null }" @click="filter.Tag = null">
              <div><img src="../../assets/img/dot.svg" alt="" />{{ $localization('web-folders-messages-all') }}</div>
            </li>
            <li :class="{ active: filter.Tag == 1 }" @click="filter.Tag = 1">
              <div><img src="../../assets/img/dot.svg" alt="" />{{ $localization('web-folders-messages-important') }}</div>
            </li>
            <li :class="{ active: filter.Tag == 2 }" @click="filter.Tag = 2">
              <div><img src="../../assets/img/dot2.svg" alt="" />{{ $localization('web-folders-messages-system') }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <div class="container">
        <div class="title">
          <div>{{ $localization('web-folders-messages-new-message') }}</div>
        </div>
        <div class="close" @click="cancelNewMessage">
          <i class="icon-close"></i>
        </div>
        <div class="title2">{{ $localization('web-folders-messages-to') }}</div>
        <div class="input-container">
          <el-select style="display: flex; flex-direction: column" v-model="sendMessage.ReceiverIds" :placeholder="$localization('web-folders-messages-search')"
            :no-match-text="$localization('web-folders-messages-no-consultant-found')" filterable multiple>
            <el-option v-for="item in doctorsNurses" :key="item.ID" :label="item.Specialist + ' ' + item.FirstName + ' ' + item.LastName + ' (' + item.PhoneNumber + ')'" :value="item.ID"> </el-option>
          </el-select>
          <span class="error">{{ $errorMessage("ReceiverIds", validation) }}</span>
        </div>
        <div class="title2">{{ $localization('web-folders-messages-subject') }}</div>
        <div class="input-container">
          <el-input v-model="sendMessage.Title"></el-input>
          <span class="error">{{ $errorMessage("Title", validation) }}</span>
        </div>
        <div class="title2">{{ $localization('web-folders-messages-message') }}</div>
        <div class="input-container">
          <div class="text-edit">
            <div id="toolbar">
              <select class="ql-size">
                <option value="small"></option>
                <!-- Note a missing, thus falsy value, is used to reset to default -->
                <option selected></option>
                <option value="large"></option>
                <option value="huge"></option>
              </select>
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>

              <select class="ql-align">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            </div>
            <quill-editor ref="myQuillEditor" :options="editorOption" v-model="sendMessage.MessageText" style="max-height: 200px; overflow-y: auto">
            </quill-editor>
          </div>
          <span class="error">{{ $errorMessage("MessageText", validation) }}</span>
        </div>

        <ul class="file-list" v-if="sendMessage.Attachments.FilenameReal">
          <li class="list-item" v-for="(item, index) in sendMessage.Attachments" :key="index">
            <div class="file">
              <img :src="getExtension(item.Extension)" />
            </div>
            <div class="details">
              <div class="title">{{ item.FilenameReal }}</div>
              <div class="byte">{{ fileSize(item.FileSizeInMb) }}</div>
            </div>
            <div class="icon-delete" @click="clearFiles(index)"></div>
          </li>
        </ul>
        <el-upload class="el-up" :action="$client.defaults.baseURL + 'Account/UploadFile?type=5'" :headers="{
          Authorization: $client.defaults.headers.common['Authorization'],
        }" :show-file-list="false" multiple :on-success="fileSuccess">
          <el-button size="small" style="justify-self: flex-start" type="primary">
            <i class="icon-plus"></i>
            {{ $localization('web-folders-messages-attach-file') }}</el-button>
        </el-upload>

        <div class="button-group-admin">
          <el-button class="cancelReportAdmin" @click="cancelNewMessage">{{ $localization('web-folders-messages-cancel') }}</el-button>
          <el-button class="confirmReportAdmin" @click="sendMessagee"><i class="icon-send"></i>{{ $localization('web-folders-messages-save')
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0,
      dialogVisible: false,
      validation: [],
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
        placeholder: this.$localization('web-folders-please-enter-message'),
      },
    };
  },
  beforeMount() {
    this.getMessages();
    if (this.currentUser.Role == 10) {
      this.getDoctors();
      this.getNurses();
    }
  },
  methods: {
    cancelNewMessage() {
      this.dialogVisible = false;
      this.sendMessage.Title = null;
      this.sendMessage.MessageText = null;
      this.sendMessage.ReceiverIds = [];
      this.sendMessage.Attachments = [];
      this.validation = [];
    },
    clearFiles(index) {
      this.sendMessage.Attachments.splice(index, 1);
    },
    fileSuccess(res) {
      this.sendMessage.Attachments.push(res.Item);
    },
    async getMessages() {
      await this.$store.dispatch("getMessagesMB", this.filter);
    },

    async getDoctors() {
      await this.$store.dispatch("getDoctorsMB", {
        Role: 2,
        PageSize: 100,
        Page: 1,
      });
    },

    async getNurses() {
      await this.$store.dispatch("getNursesMB", {
        Role: 3,
        PageSize: 100,
        Page: 1,
      });
    },
    async sendMessagee() {
      var res = await this.$store.dispatch("sendMessageMB", this.sendMessage);
      this.validation = res.ValidationErrors;
      if (!res.HasError) {
        this.dialogVisible = false;
        this.sendMessage.Title = null;
        this.sendMessage.MessageText = null;
        this.sendMessage.Attachments = [];
        this.sendMessage.ReceiverIds = [];
        this.validation = [];
      }
    },
  },
  watch: {
    "filter.MessageBoxType": function () {
      if (this.filter.MessageBoxType != 1) {
        this.filter.Tag = null;
      }

      this.$store.commit("setActiveMessageMB", null);
      this.$store.dispatch("getMessagesMB", this.filter);
    },
    "filter.Tag": function () {
      this.$store.commit("setActiveMessageMB", null);
      this.$store.dispatch("getMessagesMB", this.filter);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    filter() {
      return this.$store.getters.getFilterMB;
    },
    sendMessage() {
      return this.$store.getters.getSendMessageMB;
    },
    doctors() {
      return this.$store.getters.getDoctorsMB;
    },
    nurses() {
      return this.$store.getters.getNursesMB;
    },
    doctorsNurses() {
      var doctorsNurses = this.doctors.Items.concat(this.nurses.Items);
      return doctorsNurses;
    },
  },
};
</script>

<style lang="less">
#folders-box {
  .button-group-admin {
    margin-top: 20px;

    .confirmReportAdmin:hover {
      color: #fff;
    }
  }

  .folders {
    width: 100%;
    height: 100vh;
    background-color: #fff;

    .folders-top {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px;

      .btn-new-message {
        align-items: center;
        background: #54d0c8;
        color: #fff;
        width: 132px;
        padding-left: 10px;
        border-radius: 5px;
      }

      .content1 {
        display: flex;

        i {
          padding-right: 30px;
        }

        div {
          width: 100%;
          font-weight: 500;
          font-size: 15px;
        }
      }
    }

    .folders-bottom {
      .folders-bottom-folders {
        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            width: 100%;
            height: 48px;
            display: flex;

            &.active {
              position: relative;

              &::after {
                display: block;
                content: "";
                width: 4px;
                height: 100%;
                background-color: #54d0c8;
                left: 0;
                top: 0;
                border-radius: 0 5px 5px 0;
                position: absolute;
              }

              background: #f8fafb;
            }

            div {
              display: flex;
              gap: 10px;
              padding-left: 21px;
              color: #44566c;
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              align-items: center;
            }
          }
        }
      }

      .folders-bottom-tags {
        .tag {
          padding-left: 24px;
          padding-top: 15px;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            width: 100%;
            height: 48px;
            display: flex;

            div {
              display: flex;
              gap: 10px;
              padding-left: 21px;
              color: #44566c;
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              align-items: center;

              img {
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }

  .el-icon-close:before {
    content: "";
  }

  .el-dialog {
    width: 35%;
    height: auto;
    border-radius: 10px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      position: relative;

      .close {
        position: absolute;
        right: 1rem;
        cursor: pointer !important;
        top: 1rem;
        width: 28px;
        height: 28px;
        padding: 3px;
        border-radius: 50%;
        background-color: #eaedf0;

        .icon-close {
          width: 100%;
          height: 100%;
        }
      }
    }

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      padding: 20px;

      .text-edit {
        #toolbar {
          padding: 9px;
          border: none;
          background-color: #f8fafb !important;
          border-radius: 5px 5px 0px 0px;
          font-family: "Roboto" !important;
        }

        .ql-snow .ql-stroke {
          stroke: #8697a8;
        }

        .ql-active>.ql-snow .ql-stroke {
          stroke: #8697a8;
        }

        .ql-container {
          min-height: 100px;
          border: none !important;
          background-color: #f8fafb;
          resize: both;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          color: #8697a8;
        }
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
        display: flex;
        justify-content: space-between;
      }

      .el-input__inner {
        background: #f8fafb;
        border: none;
      }

      .title2 {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #8697a8;
      }

      .file {
        display: flex;
        gap: 1rem;

        img {
          padding: 5px;
        }

        .doc1,
        .doc2 {
          display: flex;
          align-items: center;
          height: 72px;
          gap: 25px;
          background: #f8fafb;
          padding: 10px;
        }

        i {
          align-self: center;
        }

        .text {
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: #44566c;
          align-items: center;
        }
      }
    }
  }
}
</style>
